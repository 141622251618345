import axios from "axios";
import { useContext, useReducer } from "react";
import UserContext from ".";
import { baseUrl } from "../api";
import jwt_decode from "jwt-decode"

const ACTIONS={
  SET_CODE :"SET_CODE",
  SET_TOKEN :"SET_TOKEN",
  SET_USER :"SET_USER",
}

const initalState = {
  code:"",
  token:"",
  user:""
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_CODE:
      return {...state , code:action.payload};
    case ACTIONS.SET_TOKEN:      
      return {...state , token:action.payload};
    case ACTIONS.SET_USER:      
      return {...state , user:action.payload};      

    default:
      return state;
  }
};

export const UserProvider = ({ children, ...otherProps }) => {
  const [state, dispatch] = useReducer(reducer, initalState);

  const LocalStorage = window.localStorage;
  const SessionStorage = window.sessionStorage;

  const startSession = async (data) => {
    SessionStorage.setItem("UserGUID",data);
      return true;
  };

  const setCode = async (code)=>{
    try{
      dispatch({type:ACTIONS.SET_CODE , payload:code});
    }
    catch(error){console.log(error)}
  }
  const setToken = async (token)=>{  
      let response = await axios.get(baseUrl+"portal/token/"+token);
          response = response.data   
      if(response.id_token){
        let data = response.id_token;
        data = decodeJWT(data);
        let user = await axios.get(baseUrl+"portal/user/"+data);
        user = user.data;
        user = user.toLowerCase();
        startSession(user)      
        dispatch({type:ACTIONS.SET_TOKEN , payload:data});
      }
      else{
        console.log("There is no id token" , token);
      }     
  }

  const decodeJWT = (data) => {
   let decoded = jwt_decode(data);
    decoded = (decoded.objectGUID)
    decoded = decoded.replace("==","");
    startSession(decoded);
    return decoded
  };

  const value = { state, startSession, setCode ,setToken , decodeJWT, SessionStorage , LocalStorage };
  return (
    <UserContext.Provider value={value} {...otherProps}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserProvider = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserProvider must be used within a UserProvider");
  }
  return context;
};
