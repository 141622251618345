import { useState } from "react";
import { Container, NavButton,FirstDiv, SecondDiv, ThirdDiv, Title } from "./styles";
const Navbar = ({cb}) => {
  const [isVerify, setIsVerify]=useState(false);
  const onClickhandler = ()=>{
    cb()
    setIsVerify(!isVerify)
  }
  return (
    <>
      <Container>
        <FirstDiv>
        </FirstDiv>
        <SecondDiv></SecondDiv>
        <ThirdDiv>
          <NavButton onClick={()=>{onClickhandler()}}>Crecimiento</NavButton>
        </ThirdDiv>
      </Container>
    </>
  );
};

export default Navbar;
