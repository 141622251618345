import {General} from "../../styled";
import {Link, useNavigate} from "react-router-dom";
import Navbar from "../../navBar/navBar";
import {Style} from "./Styles";
import {useEffect, useState} from "react";
import {checkMobileBrowser} from "../../utils";
import { postUserInfo } from "../../api";
import { useUserProvider } from "../../context/provider";

function PreLanding() {
  const navigate = useNavigate();
  const {SessionStorage} = useUserProvider();
  const isMobile = checkMobileBrowser();
  const [name , setName] = useState('');
  const [alias , setAlias] = useState('');
  const [familyName , setFamilyName] = useState('');
  const [company , setCompany] = useState('');
  const [formActive , setFormActive] = useState(false);
  const [switchForm , setSwitchForm] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let response
    
    if(switchForm){
      if(name === '' || familyName === '' ){
        alert('Todos los campos son obligatorios')
        return
      }
       response = await postUserInfo({name:name + ' ' + familyName , company});
    }else{
      if(alias === '' ){
        alert('Todos los campos son obligatorios')
        return
      }
      response = await postUserInfo({name:alias, company});
    }

    console.log('RESPONSE' , response)
    

    if (response) {
      const json = JSON.stringify(response.data);
      console.log('DATA' , json);
      SessionStorage.setItem('user', json);
      navigate('/login')
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "familyName":
        setFamilyName(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "alias":
        setAlias(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <General.View>
        <Navbar/>
        <General.Body>
          <Style.Container>
            <Style.Login>
              <Style.Title>Get your Crecimiento Credential</Style.Title>
              {!formActive ? !isMobile ? (
                <>
                  Please make click on the following button to fill out the form to get your credential
                  <Style.FormRow>
                    <a onClick={()=>{setFormActive(true)}}>
                      Complete the form with your personal information
                    </a>
                  </Style.FormRow>
                </>
              ) : (
                <Style.Button onClick={()=>{setFormActive(true)}}>
                  Form
                </Style.Button>
              ):
              <Style.FormContainer>

                {switchForm?
                <>
                <Style.FormInput placeholder="Name" name="name" onChange={(e)=>{handleInputChange(e)}}></Style.FormInput>
                <Style.FormInput placeholder="Family Name" name="familyName" onChange={(e)=>{handleInputChange(e)}}></Style.FormInput>
                </>:
                <Style.FormInput placeholder="Alias" name="alias" onChange={(e)=>{handleInputChange(e)}} ></Style.FormInput>}
                <Style.SwitchButton onClick={()=>{setSwitchForm(!switchForm)}}>{switchForm?'I prefer to give an Alias' : 'I prefer to use my full name'}</Style.SwitchButton>
                <Style.FormInput placeholder="Company" name="company" onChange={(e)=>{handleInputChange(e)}} ></Style.FormInput>
                <Style.FormButton onClick={handleSubmit}>Send</Style.FormButton>
              </Style.FormContainer>
              }
            </Style.Login>
            <Style.RightSide></Style.RightSide>
          </Style.Container>
        </General.Body>
        <General.Lower/>
      </General.View>
    </>
  );
}

export default PreLanding;
