
import {Route, Routes} from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Landing from "./views/Landing";
import PreLanding from "./views/PreLanding";
import Verifier from "./views/Verifier";

function App() { 

  return (
    <Routes>
        <Route path="/" element={<PreLanding/>}/>
        <Route path="/login" element={<Landing/>}/>
				<Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/verifier" element={<Verifier/>}/>
		</Routes>
  );
}

export default App;
